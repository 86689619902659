import "jquery";
import "bootstrap";

// @ts-ignore
import Vue from "vue";
import VueTimeago from "vue-timeago";

// @ts-ignore
import upperFirst from "lodash/upperFirst";

// @ts-ignore
import camelCase from "lodash/camelCase";

// @ts-ignore
import VueMoment from "vue-moment";

// @ts-ignore
import VueMatomo from "vue-matomo";

// @ts-ignore
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

// @ts-ignore
import VueEasyLightbox from "vue-easy-lightbox";

// @ts-ignore
import LazyLoad from "vanilla-lazyload";

// @ts-ignore
import VueAuthImage from "vue-auth-image";

// @ts-ignore
import moment from "moment";
import { RouteConfig } from "vue-router/types/router";

Vue.use(VueAuthImage);
Vue.use(VueMoment);
Vue.use(Antd);
Vue.use(VueEasyLightbox);
Vue.prototype.lazyLoadInstance = new LazyLoad({
  cancel_on_exit: false,
  threshold: 99999 // dummy big value to load all image sizes
});

Vue.use(VueTimeago, {
  name: "Timeago",
  locale: "de",
  locales: {
    de: require("date-fns/locale/de")
  }
});

Vue.config.productionTip = false;

Vue.filter("momentjs_date_localized", function(
  value: any,
  format: string = "YYYY",
  locale: string = "de"
) {
  if (!value) {
    return "";
  }

  moment.locale(locale);
  return moment(value).format(format);
});

function setupMatomo(Store: any) {
  if (!Store.matomoEnabled()) {
    return;
  }

  Vue.use(VueMatomo, {
    // router: router,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    host: process.env.VUE_APP_MATOMO_HOST,
    trackerScriptUrl: process.env.VUE_APP_MATOMO_HOST + "/matomo.js",
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: process.env.VUE_APP_MATOMO_HEARTBEAT_TIMER
      ? parseInt(process.env.VUE_APP_MATOMO_HEARTBEAT_TIMER)
      : 20,
    trackInitialView: false,
    requireConsent: true,
    requireCookieConsent: true,
    debug: process.env.VUE_APP_MATOMO_DEBUG
      ? parseInt(process.env.VUE_APP_MATOMO_DEBUG) == 1
      : true
  });
}

const initApp = async () => {
  const vueApp = (await import(`./AppCancer360.vue`)).default;
  const { Store } = await import("common");
  const { Bootstrap } = await import("common");
  const { router } = await import("common");

  let allRoutes = await Bootstrap.initRoutes();

  allRoutes.forEach((route: RouteConfig) => {
    router.addRoute(route);
  });

  let app = document.querySelector("#app");
  if (app) {
    app.classList.remove("intializing");
  }

  setupMatomo(Store);

  new Vue({
    router,
    render: h => h(vueApp)
  }).$mount("#app");
};

function createContext(context: any[]) {
  for (let contextItem of context) {
    contextItem.keys().forEach((fileName: any) => {
      const componentConfig = contextItem(fileName);
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          fileName
            .split("/")
            .pop()
            .replace(/\.\w+$/, "")
        )
      );

      Vue.component(componentName, componentConfig.default || componentConfig);
    });
  }
}

initApp().then(() => {
  const requireComponent = require.context(
    "vue-ui/components",
    true,
    /[\w_]*\.(vue)$/
  );
  const requireLocalComponent = require.context(
    "./components",
    true,
    /[\w_]*\.(vue)$/
  );
  createContext([requireComponent, requireLocalComponent]);
});