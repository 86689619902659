export const RAW_FILTERS = [
  {
    name: "Topics",
    value: "topic",
    items: [
      {
        name: "Financial Support",
        value: "financial-support"
      },
      {
        name: "Nutrition and wellness",
        value: "nutrition-and-wellness"
      },
      {
        name: "Cancer treatment support",
        value: "cancer-treatment-support"
      },
      {
        name: "Emotional support",
        value: "emotional-support"
      },
      {
        name: "Managing cancer symptoms",
        value: "managing-cancer-symptoms"
      },
      {
        name: "Survivorship",
        value: "survivorship"
      },
      {
        name: "Cancer basics",
        value: "cancer-basics"
      },
      {
        name: "Clinical trials",
        value: "clinical-trials"
      },
      {
        name: "Cancer causes",
        value: "cancer-causes"
      },
      {
        name: "Stories of Hope",
        value: "stories-of-hope"
      }
    ]
  },
  {
    name: "Cancer Types",
    value: "cancer_type",
    items: [
      {
        name: "Biliary Tract",
        value: "biliary"
      },
      {
        name: "Blood",
        value: "blood"
      },
      {
        name: "Breast",
        value: "breast"
      },
      {
        name: "Cervical",
        value: "cervical"
      },
      {
        name: "Liver",
        value: "liver"
      },
      {
        name: "Lung",
        value: "lung"
      },
      {
        name: "Ovarian",
        value: "ovarian"
      },
      {
        name: "Pancreatic",
        value: "pancreatic"
      },
      {
        name: "Prostate",
        value: "prostate"
      },
      {
        name: "Stomach",
        value: "stomach"
      }
    ]
  },
  {
    name: "Content Types",
    value: "content_type",
    items: [
      {
        name: "Articles",
        value: "articles"
      },
      {
        name: "Stories",
        value: "stories"
      },
      {
        name: "Guides",
        value: "guides"
      },
      {
        name: "Links",
        value: "links"
      }
    ]
  },
  {
    name: "Site Visitor",
    value: "site_visitor",
    items: [
      {
        name: "Patient",
        value: "patient"
      },
      {
        name: "Caregiver",
        value: "caregiver"
      }
    ]
  }
];

export const CANCER_SUPPORT_360_APP_BRAND = "cancersupport360_hub";
